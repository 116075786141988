import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { NetworkService } from './network.service';
import { BehaviorSubject } from 'rxjs';
import { MatchWithWrestlers, MatchActivityUpdate, MatchUpdate, MatchWithWrestlersAndSettingsAndBracket } from '../lib/collection.types';

interface StoredMatch {
  eventId: string;
  mat: number;
  matches: MatchWithWrestlers[];
  timestamp: string;
}

interface PendingBoutEvent {
  event: MatchActivityUpdate;
  timestamp: string;
  retryCount: number;
}

interface PendingMatchUpdate {
  match: MatchWithWrestlersAndSettingsAndBracket;
  timestamp: string;
  retryCount: number;
}

export interface SyncStatus {
  pendingCount: number;
  isSyncing: boolean;
  lastSyncAttempt?: Date;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {
  private initialized = false;
  private pendingSync = new BehaviorSubject<number>(0);
  pendingSync$ = this.pendingSync.asObservable();

  private syncStatus = new BehaviorSubject<SyncStatus>({
    pendingCount: 0,
    isSyncing: false
  });
  syncStatus$ = this.syncStatus.asObservable();

  constructor(
    private storage: Storage,
    private networkService: NetworkService
  ) {
    this.init();
  }

  private async init() {
    if (!this.initialized) {
      await this.storage.create();
      this.initialized = true;
      await this.updateSyncStatus();
    }
  }

  // Cache matches for a mat when they're downloaded
  async cacheMatches(eventId: string, mat: number, matches: MatchWithWrestlers[]) {
    const key = `matches_${eventId}_${mat}`;
    const data: StoredMatch = {
      eventId,
      mat,
      matches,
      timestamp: new Date().toISOString()
    };
    await this.storage.set(key, data);
  }

  // Get cached matches for a mat
  async getCachedMatches(eventId: string, mat: number): Promise<MatchWithWrestlers[] | null> {
    const key = `matches_${eventId}_${mat}`;
    const data: StoredMatch | null = await this.storage.get(key);
    return data?.matches || null;
  }

  // Store a bout event when offline or when save fails
  async storePendingBoutEvent(event: MatchActivityUpdate) {
    const key = `pending_events`;
    const pendingEvents: PendingBoutEvent[] = await this.storage.get(key) || [];

    pendingEvents.push({
      event,
      timestamp: new Date().toISOString(),
      retryCount: 0
    });

    await this.storage.set(key, pendingEvents);
    await this.updateSyncStatus();
  }

  // Store match update when offline (only keep latest)
  async storePendingMatchUpdate(match: MatchWithWrestlersAndSettingsAndBracket) {
    const key = `pending_match_${match.id}`;
    const update: PendingMatchUpdate = {
      match,
      timestamp: new Date().toISOString(),
      retryCount: 0
    };
    await this.storage.set(key, update);
    await this.updateSyncStatus();
  }

  // Get pending match updates
  async getPendingMatchUpdates(): Promise<PendingMatchUpdate[]> {
    const keys = await this.storage.keys();
    const matchKeys = keys.filter(key => key.startsWith('pending_match_'));
    const updates: PendingMatchUpdate[] = [];

    for (const key of matchKeys) {
      const update = await this.storage.get(key);
      if (update) {
        updates.push(update);
      }
    }

    return updates;
  }

  // Remove pending match update after successful sync
  async removePendingMatchUpdate(matchId: number) {
    const key = `pending_match_${matchId}`;
    await this.storage.remove(key);
    await this.updateSyncStatus();
  }

  // Get pending events for processing
  async getPendingEvents(): Promise<PendingBoutEvent[]> {
    const key = `pending_events`;
    return await this.storage.get(key) || [];
  }

  // Update pending events after processing
  async updatePendingEvents(events: PendingBoutEvent[]) {
    const key = `pending_events`;
    await this.storage.set(key, events);
    await this.updateSyncStatus();
  }

  // Set sync status
  async setSyncStatus(status: Partial<SyncStatus>) {
    const currentStatus = this.syncStatus.value;
    this.syncStatus.next({
      ...currentStatus,
      ...status,
      lastSyncAttempt: status.isSyncing ? new Date() : currentStatus.lastSyncAttempt
    });
  }

  // Update the sync status based on current pending items
  private async updateSyncStatus() {
    const pendingEvents = await this.getPendingEvents();
    const pendingUpdates = await this.getPendingMatchUpdates();
    const pendingCount = pendingEvents.length + pendingUpdates.length;

    this.pendingSync.next(pendingCount);

    const currentStatus = this.syncStatus.value;
    this.syncStatus.next({
      ...currentStatus,
      pendingCount
    });
  }

  // Get number of pending items (both events and match updates)
  async getPendingCount(): Promise<number> {
    const pendingEvents = await this.getPendingEvents();
    const pendingUpdates = await this.getPendingMatchUpdates();
    return pendingEvents.length + pendingUpdates.length;
  }

  // Clear error state
  async clearError() {
    const currentStatus = this.syncStatus.value;
    this.syncStatus.next({
      ...currentStatus,
      error: undefined
    });
  }
}
