import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
// import { CapacitorUpdater } from '@capgo/capacitor-updater';

const bootstrap = async () => {

  // in package.json, add // "@capgo/capacitor-updater": "^6.4.1",
  // await CapacitorUpdater.notifyAppReady();

  if (environment.production) {
    enableProdMode();
  }

  await platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));

  defineCustomElements(window);
};

bootstrap();
