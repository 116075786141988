<div class="flex-column flex-between">
  <div>
    <ion-toolbar color="secondary" *ngIf="showClose">
      <ion-title> Subscription </ion-title>
      <ion-buttons slot="start">
        <ion-button *ngIf="showClose" (click)="cancel()"> Close </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <!-- <ion-button (click)="restore()">Restore</ion-button> -->
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div>
    <ng-container *ngIf="vm$ | async as vm">
      <ion-card color="danger" *ngIf="!vm.user.available"
        ><ion-card-content>
          <h2>
            Sorry, Snapdown Pro is currently only available on Mobile devices.
          </h2>
        </ion-card-content>
      </ion-card>

      <ion-toolbar color="light">
        <ion-segment
          value="pro"
          mode="ios"
          (ionChange)="segmentChanged($event)"
        >
          <ion-segment-button value="pro">
            <ion-label>Pro</ion-label>
          </ion-segment-button>
          <ion-segment-button value="basic">
            <ion-label>Basic</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>

      <div [ngSwitch]="whichToShow">
        <div color="light" class="features" *ngSwitchCase="'pro'">
          <div class="ion-text-start">
            Snapdown Pro gives you access to all features:
            <ul>
              <li>Match Schedules, Brackets, Match Results</li>
              <li>Wrestler Records and Match History</li>
              <li>Detailed Wrestler, Team, and League Stats</li>
              <li>Easier Viewing of Matchups and Opponents</li>
              <li>Live Match Scores</li>
              <li>
                Combined league wrestler view for searching and comparison
              </li>
            </ul>
            <ion-list>
              <ng-container *ngIf="(vm.user.pro) ">
                <ion-item color="success">You have an active subscription</ion-item>
              </ng-container>
              <ng-container *ngIf="(vm.user.basic) ">
                <ion-item color="warning">You have a Basic subscription. You can upgrade to Pro</ion-item>
              </ng-container>

              <ng-container *ngIf="!(vm.user.pro) ">


                <!-- *ngFor="let sku of offeringGroup.skus" -->
              <ng-container *ngFor="let package of vm.proOffers">
                <ion-item
                  color=""
                  class="offering"
                  >
                  <!-- (click)="purchase(sku)" -->
                  <div class="flex-column">
                    <div class="flex-between">
                      <ion-label class="ion-text-wrap">
                        {{ package.product.title }}
                      </ion-label>
                      <ion-button (click)="purchase(package.identifier)">
                        {{
                          package.product.price
                            | number: "1.0-2"
                            | currency: package.product.currencyCode
                        }}
                        <ng-container *ngIf="package.product.identifier.includes('1month')"
                          >/ month</ng-container
                        >
                        <ng-container *ngIf="package.product.identifier.includes('year')"
                          >/ year</ng-container
                        >
                      </ion-button>
                    </div>
                    <div *ngIf="package.product.identifier.includes('year')">
                      <ion-note class="note"
                        >Only
                        {{
                          package.product.price / 12.0
                            | number: "1.0-2"
                            | currency: package.product.currencyCode
                        }}/mo with an annual subscription</ion-note
                      >
                    </div>
                  </div>
                </ion-item>
              </ng-container>
            </ng-container>
            </ion-list>
          </div>
        </div>

        <div color="light" class="features" *ngSwitchCase="'basic'">
          <div class="ion-text-start">
            Snapdown Basic gives you access to:
            <ul>
              <li>Match Schedules</li>
            </ul>

            No access to:
            <ul>
              <li>Brackets, Match Results</li>
              <li>Wrestler Records and Match History</li>
              <li>Detailed Wrestler, Team, and League Stats</li>
              <li>Easier Viewing of Matchups and Opponents</li>
              <li>Live Match Scores</li>
              <li>
                Combined league wrestler view for searching and comparison
              </li>
            </ul>
            <ion-list>
              <ng-container *ngIf="(vm.user.basic) ">
                <ion-item color="success">You have an active subscription</ion-item>
              </ng-container>
              <ng-container *ngIf="(vm.user.pro) ">
                <ion-item>You have a Pro subscription. You can downgrade to Basic</ion-item>
              </ng-container>

              <ng-container *ngIf="!(vm.user.basic) ">
              <ng-container *ngFor="let package of vm.basicOffers">
                <!-- *ngFor="let sku of offeringGroup.skus" -->
                <ion-item
                  color=""
                  class="offering"
                  >
                  <!-- (click)="purchase(sku)" -->
                  <div class="flex-column">
                    <div class="flex-between">
                      <ion-label class="ion-text-wrap">
                        {{ package.product.title }}
                      </ion-label>
                      <ion-button (click)="purchase(package.identifier)">
                        {{
                          package.product.price
                            | number: "1.0-2"
                            | currency: package.product.currencyCode
                        }}
                        <ng-container *ngIf="package.product.identifier.includes('1month')"
                          >/ month</ng-container
                        >
                        <ng-container *ngIf="package.product.identifier.includes('year')"
                          >/ year</ng-container
                        >
                      </ion-button>
                    </div>
                    <div *ngIf="package.product.identifier.includes('year')">
                      <ion-note class="note"
                        >Only
                        {{
                          package.product.price / 12.0
                            | number: "1.0-2"
                            | currency: package.product.currencyCode
                        }}/mo with an annual subscription</ion-note
                      >
                    </div>
                  </div>
                </ion-item>
              </ng-container>
            </ng-container>
            </ion-list>
          </div>
        </div>

        <!-- ion-grid with 10 rows, each row with 4 columns (feature, Pro, Basic, Free) -->
        <!-- <ion-grid>
          <ion-row>
            <ion-col size="6">
              <ion-label>Feature</ion-label>
            </ion-col>
            <ion-col size="2">
              <ion-label>Pro</ion-label>
            </ion-col>
            <ion-col size="2">
              <ion-label>Basic</ion-label>
            </ion-col>
            <ion-col size="2">
              <ion-label>Free</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6"> Leagues </ion-col>
            <ion-col size="2">
              <ion-icon color="danger" name="close-outline"></ion-icon>
            </ion-col>
            <ion-col size="2">
              <ion-icon color="success" name="checkmark-outline"></ion-icon>
            </ion-col>
            <ion-col size="2">
              <ion-icon color="success" name="checkmark-outline"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid> -->

        <!-- <ion-card>
          <ion-card-content class="ion-no-padding">
            <ion-item lines="none" [color]="vm.user.pro ? 'success' : vm.user.basic ? 'success' : 'danger'">
              <ion-label>
                Current Subscription: {{ vm.user.pro ? "Pro" : vm.user.basic ? 'Basic' : "None" }}
              </ion-label>
            </ion-item>
          </ion-card-content>
        </ion-card> -->
      </div>
    </ng-container>
    <!-- <div *ngFor="let e of errors">
  {{e}}
</div> -->
  </div>
  <div>
    <div class="flex-column">
      <ion-button fill="clear" size="small" (click)="restore()"
        >Restore Purchases</ion-button
      >
      <div class="flex-between small-padding links">
        <a
          href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
          >Terms of Service</a
        >
        <a href="https://app.snapdown.io/privacy">Privacy Policy</a>
      </div>
    </div>
  </div>
</div>
