// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // supabaseUrl: 'http://localhost:54321',
  // supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0',
  supabaseUrl: 'https://pbhmprmeoskskonrzzxb.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBiaG1wcm1lb3Nrc2tvbnJ6enhiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjEyNTI3NzMsImV4cCI6MTk3NjgyODc3M30.n9fe5OiVChCDOQvjR8nDQFgXDffSkuxzg_vACxg_JkM',
	glassfyKey: '5b70d810c6bb47caae119dd5488318df',
  mode: 'dev',
  revenuecatKeyGoogle: 'goog_oJvNwWDQHkDDNBJlsTMgdxkjedK',
  revenuecatKeyApple: 'appl_LxAbuXGfuXHtwHTwUbdhRmPLUFB',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
