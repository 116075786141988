import { ModalController, Platform } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { filter, map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @Input() showClose = true;
  offerings$ = this.productService.getOfferings();

  proOffers$ = this.offerings$.pipe(
    map(
      (offerings) =>
        offerings?.availablePackages
          .filter((o) => o.product.identifier.includes('pro'))
          .map((pkg) => ({
            ...pkg,
            product: {
              ...pkg.product,
              title: pkg.product.title.replace(' (Snapdown Wrestling)', ''),
            },
          })),
    ),
  );

  basicOfferings$ = this.offerings$.pipe(
    map(
      (offerings) =>
        offerings?.availablePackages
          .filter((o) => o.product.identifier.includes('basic'))
          .map((pkg) => ({
            ...pkg,
            product: {
              ...pkg.product,
              title: pkg.product.title.replace(' (Snapdown Wrestling)', ''),
            },
          })),
    ),
  );
  user$ = this.productService.user$;

  vm$ = combineLatest([
    this.offerings$,
    this.user$,
    this.proOffers$,
    this.basicOfferings$,
  ]).pipe(
    map(([offerings, user, proOffers, basicOffers]) => ({
      offerings,
      user,
      proOffers,
      basicOffers,
    })),
  );

  errors = this.productService.errors;
  constructor(
    private productService: ProductService,
    private modalController: ModalController,
    platform: Platform,
  ) {}
  ngOnInit(): void {}
  async purchase(sku: string) {
    await this.productService.purchase(sku);
  }

  async restore() {
    await this.productService.restore();
  }

  whichToShow = 'pro';

  segmentChanged(event: any) {
    this.whichToShow = event.detail.value;
  }

  cancel() {
    this.modalController.dismiss();
  }
}
