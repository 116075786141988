import { DataService } from 'src/app/services/data.service';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SupabaseClient } from '@supabase/supabase-js';
import { AlertController, isPlatform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private supabase: SupabaseClient;
  private _currentUser;

  router = inject(Router);
  dataService = inject(DataService);
  alertController = inject(AlertController);

  async loadUser() {
    if (this._currentUser.value) {
      return;
    }
  }
  constructor() {
    // Use client from data service, no need to have two clients.
    this.supabase = this.dataService.client;
    this._currentUser = this.dataService._currentUser;

    // this.supabase = createClient(
    //   environment.supabaseUrl,
    //   environment.supabaseKey
    // );

    setTimeout(async () => {
      // console.log('load user after 1 second');

      if (!this._currentUser.value) {
        // console.log('load user after 1 second, no user yet loaded');

        const {
          data: { user },
        } = await this.supabase.auth.getUser();

        // console.log('load user after 1 second, user:', user);

        if (user) {
          this._currentUser.next(user);
        } else {
          this._currentUser.next(false);
        }
      }
    }, 1000);

    this.supabase.auth.onAuthStateChange(async (event, session) => {
      // console.log('auth event, ', event, session);
      if (
        event === 'SIGNED_IN' ||
        event == 'TOKEN_REFRESHED' ||
        event == 'INITIAL_SESSION'
      ) {
        // console.log('set next user value:', session);

        // console.log(session?.user?.app_metadata); // show custom claims

        this._currentUser.next(session?.user);
      } else if (event === 'PASSWORD_RECOVERY') {
        await this.showPasswordResetPrompt();
        this._currentUser.next(session?.user);
      } else {
        this._currentUser.next(false);
        console.log('navigate back to /');

        this.router.navigateByUrl('/', { replaceUrl: true });
      }
    });
  }
  async showPasswordResetPrompt() {
    const alert = await this.alertController.create({
      header: 'Enter your desired password',
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          handler: (alertData) => {
            if (!alertData || !alertData.password) {
              this.dataService.showToast('New password is required', {
                position: 'middle',
              });
              return false;
            }
            if (alertData.password.length < 5) {
              this.dataService.showToast(
                'Password must be at least 5 characters',
                { position: 'middle' },
              );
              return false;
            }
          },
        },
      ],
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password',
        },
      ],
    });

    await alert.present();
    const result = await alert.onDidDismiss();
    // console.log(result.data);
    const password = result.data.values.password;
    const { data, error } = await this.supabase.auth.updateUser({ password });
    if (error) {
      this.dataService.showToast('Error, please try again later');
    }
  }

  getRedirectByPlatform() {
    const redirectTo = isPlatform('capacitor')
      ? 'snapdown://home/'
      : window.location.origin;
    return redirectTo;
  }

  sendPasswordReset(email: string) {
    const redirectTo = this.getRedirectByPlatform();
    return this.supabase.auth.resetPasswordForEmail(
      email,

      { redirectTo },
    );
  }
  magicLinkSignIn(email: string) {
    const emailRedirectTo = this.getRedirectByPlatform();
    return this.supabase.auth.signInWithOtp({
      email,
      options: { emailRedirectTo },
    });
  }

  signInWithEmail(credentials: any) {
    return this.supabase.auth.signInWithPassword({
      email: credentials.email,
      password: credentials.password,
    });
  }

  signUpWithEmail(credentials: any) {
    // console.log('credentials:', credentials);

    return this.supabase.auth.signUp({
      email: credentials.email,
      password: credentials.password,
    });
  }

  async delete() {
    await this.dataService.deleteSelfUser();
  }

  async logout() {
    await this.supabase.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  get currentUser() {
    // console.log('current user', this._currentUser.value);

    return this._currentUser.asObservable();
  }
}
