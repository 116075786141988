import { ConfettiService } from './../confetti.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable, OnInit } from '@angular/core';
import {
  ToastController,
  AlertController,
  ModalController,
  Platform,
} from '@ionic/angular';
import {
  CustomerInfo,
  GoogleProductChangeInfo,
  LOG_LEVEL,
  Purchases,
  PurchasesOffering,
  PurchasesOfferings,
} from '@revenuecat/purchases-capacitor';
import { ProductsComponent } from '../components/products/products.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from './data.service';
import { StorageService } from './storage.service';

interface UserSubscriptionStatus {
  available: boolean;
  pro: boolean;
  basic: boolean;
  // sku: GlassfySku | undefined; // sku is either a GlassfySku object or an empty string
  customerInfo: CustomerInfo | null;
}

@Injectable({
  providedIn: 'root',
})
export class ProductService implements OnInit {
  // define a type for our behavior subject values

  private user = new BehaviorSubject<UserSubscriptionStatus>({
    available: false,
    pro: false,
    basic: false,
    // sku: undefined,
    customerInfo: null,
  });
  // user$ = this.user.asObservable();
  user$ = combineLatest([
    this.user.asObservable(),
    this.dataService.currentUser,
  ]).pipe(
    map(([userStatus, currentUser]) => {
      if (userStatus.available && currentUser) {
        if (currentUser?.email === 'demo@snapdown.io') {
          return { ...userStatus, basic: false, pro: true };
        }
        if (currentUser?.email === 'demo2@snapdown.io') {
          return { ...userStatus, pro: false, basic: true };
        }
        if (currentUser?.email === 'robert@erafx.com') {
          return { ...userStatus, pro: true, basic: false };
        }
      }
      return userStatus;
    }),
  );

  private offerings$: BehaviorSubject<PurchasesOffering | null> =
    new BehaviorSubject<PurchasesOffering | null>(null);

  errors: string[] = [];
  isNative = false;
  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private confettiService: ConfettiService,
    public platform: Platform,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private storage: StorageService,
  ) {
    // console.log('init glassfy');
    this.isNative = platform.is('capacitor');
    this.initRevenueCat();
  }

  getRevenueCatKey() {
    if (this.platform.is('android')) {
      return environment.revenuecatKeyGoogle;
    } else {
      //if (this.platform.is('ios')) {
      return environment.revenuecatKeyApple;
    }
  }

  async getRcOfferings() {
    try {
      const offerings = await Purchases.getOfferings();
      console.log('RevenueCat Offerings:', JSON.stringify(offerings));
      this.offerings$.next(offerings.current ? offerings.current : null);
    } catch (e: any) {
      console.error('Error fetching offerings:', e);

      // show alert
      // const alert = await this.alertController.create({
      //   header: 'Error fetching offerings',
      //   message: e.message,
      //   buttons: ['OK'],
      // });
    }
  }

  // Returns True is permission granted, False if not
  handleRcPermissions(customerInfo: CustomerInfo) {
    // console.log('handleRcPermissions', customerInfo);
    const pro =
      typeof customerInfo.entitlements.active['pro_features'] !== 'undefined';
    const basic =
      typeof customerInfo.entitlements.active['basic_access'] !== 'undefined';
    // console.log('isPro', isPro);
    let user = this.user.getValue();
    if (!user) {
      user = { available: true, pro, basic, customerInfo };
      console.log('no user set,override pro false', user);
    } else {
      user.available = true;
      user.pro = pro;
      user.basic = basic;
      user.customerInfo = customerInfo;
    }
    // hack for local testing
    // user.pro = true;
    // user.available = true;
    this.user.next(user);
    return user.pro;
  }

  async initRevenueCat() {
    let isAvailable = false;

    // need to handle isAvailable better....

    // console.log('init RevenueCat', new Date().toISOString());
    try {
      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
      await Purchases.configure({
        apiKey: this.getRevenueCatKey(),
      });

      const hasSynced = await this.storage.getPref('RC_PURCHASES_SYNCED');
      if (!hasSynced) {
        await Purchases.syncPurchases();
        await this.storage.setPref('RC_PURCHASES_SYNCED', 'true');
        console.log('RevenueCat purchases synced');
      }

      await this.getRcOfferings();

      // console.log('RevenueCat initialize done', new Date().toISOString());
      // console.log('RevenueCat api key', this.getRevenueCatKey());
      const ci = await Purchases.getCustomerInfo();
      // console.log('RevenueCat customer info', JSON.stringify(ci));
      this.handleRcPermissions(ci.customerInfo);

      await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
        // console.log('customerInfoUpdateListener', customerInfo);
        this.handleRcPermissions(customerInfo);
      });

      // Get all offerings (products)
      isAvailable = true;
    } catch (e: any) {
      console.log('RevenueCat init error: ', e);
      this.errors.push('RevenueCat init error: ', e);

      if (!this.isNative) {
        // add fake offerings for testing
        const sampleOffering: PurchasesOfferings = this.getSampleOfferings();

        // console.log('glassfy OFFERINGS...', sampleOffering);
        this.offerings$.next(sampleOffering.current);
      }
    }

    // log whether the offerings are even available on this platform
    let user = this.user.getValue();
    if (!user) {
      user = {
        available: isAvailable,
        pro: false,
        basic: false,
        customerInfo: null,
      };
      console.log('no user set,override pro false', user);
    }
    // hack for local testing
    // user.pro = true;
    // user.available = true;
    this.user.next(user);
  }

  async purchase(packageIdentifier: string) {
    this.spinnerShow();
    try {
      const offering = await Purchases.getOfferings();
      if (!offering || !offering.current) {
        throw new Error('No offering available');
      }

      const pack = offering.current.availablePackages.find(
        (pkg) => pkg.identifier === packageIdentifier,
      );

      if (!pack) {
        throw new Error('Package not found');
      }

      let lastCustomerInfo = this.user.getValue().customerInfo;
      const isAndroid = this.platform.is('android');
      let googleProductChangeInfo: GoogleProductChangeInfo | null = null;

      if (
        isAndroid &&
        lastCustomerInfo?.activeSubscriptions &&
        lastCustomerInfo.activeSubscriptions.length > 0
      ) {
        googleProductChangeInfo = {
          oldProductIdentifier: lastCustomerInfo.activeSubscriptions[0],
        };
      }

      const { customerInfo, productIdentifier } =
        await Purchases.purchasePackage({
          aPackage: pack,
          googleProductChangeInfo,
        });

      console.log('Purchase successful', customerInfo);
      this.handleSuccessfulPurchase(customerInfo);
    } catch (e: any) {
      console.error('Purchase failed', e);
      const alert = await this.alertController.create({
        header: 'Purchase failed',
        message: e.message,
        buttons: ['OK'],
      });
      await alert.present();
    }
    this.spinnerHide();
  }

  private handleSuccessfulPurchase(customerInfo: CustomerInfo) {
    this.handleRcPermissions(customerInfo);
    this.confettiService.realistic();
    this.presentSuccessToast('Thanks for your purchase!');
  }

  private handleSuccessfulRestore(customerInfo: CustomerInfo) {
    const isPro = this.handleRcPermissions(customerInfo);

    if (isPro) {
      this.presentSuccessToast('Your purchase has been restored!');
    } else {
      this.presentAlert('No active subscriptions found');
    }
  }

  private async presentSuccessToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 2000,
    });
    toast.present();
  }

  private async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Restore Result',
      message: message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async restore() {
    this.spinnerShow();
    try {
      const { customerInfo } = await Purchases.restorePurchases();
      console.log('Restore successful', customerInfo);
      this.handleSuccessfulRestore(customerInfo);
    } catch (e) {
      console.error('Restore failed', e);
      const alert = await this.alertController.create({
        header: 'Restore failed',
        message: 'No purchases found or an error occurred.',
        buttons: ['OK'],
      });
      await alert.present();
    }
    this.spinnerHide();
  }
  spinnerShow() {
    this.spinner.show('blank');
  }
  spinnerHide() {
    this.spinner.hide('blank');
  }
  async showProductModal() {
    let modal = await this.modalCtrl.create({
      component: ProductsComponent,
      breakpoints: [0, 1.0],
      initialBreakpoint: 1.0,
      // cssClass: 'auto-height',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      // console.log('confirm button');
    }
  }

  async ngOnInit() {
    // await this.initGlassfy();
  }

  // async initGlassfy() {
  //   let isAvailable = false;
  //   try {
  //     // Initialise Glassfy
  //     await Glassfy.initialize({
  //       apiKey: environment.glassfyKey,
  //       watcherMode: false,
  //     });

  //     // Get all user permissions
  //     const permissions = await Glassfy.permissions();
  //     this.handleExistingPermissions(permissions.all);

  //     // Get all offerings (products)
  //     const offerings = await Glassfy.offerings();
  //     // console.log('glassfy OFFERINGS...', JSON.stringify(offerings));
  //     this.errors.push('glassfy OFFERINGS...', JSON.stringify(offerings));
  //     isAvailable = true;
  //     this.offerings$.next(offerings.all);
  //   } catch (e) {
  //     console.log('glassfy init error: ', e);
  //     this.errors.push('glassfy init error: ', e as string);
  //     if (!this.isNative) {
  //       // add fake offerings for testing
  //       const sampleOffering: GlassfyOffering[] = [
  //         {
  //           offeringId: 'Snapdown Pro',
  //           skus: [
  //             {
  //               skuId: '1month_monthly_sku_001',
  //               productId: 'product12345',
  //               store: GLASSFY_STORE.AppStore, // Assuming APP_STORE is one of the GLASSFY_STORE values
  //               introductoryEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
  //               promotionalEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming NOT_ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
  //               extravars: {
  //                 sampleKey: 'sampleValue',
  //               },
  //               product: {
  //                 title: 'Full Access (1 Month)',
  //                 identifier: 'product12345',
  //                 description: 'Subscribe on a monthly basis.',
  //                 price: 4.99,
  //                 currencyCode: 'USD',
  //                 period: '1 month',
  //                 introductoryPrice: {
  //                   identifier: 'intro_monthly_001',
  //                   price: 4.99,
  //                   currencyCode: 'USD',
  //                   period: '1 month',
  //                   numberOfPeriods: 1,
  //                   type: 'introductory',
  //                 },
  //                 discounts: [],
  //                 basePlanId: 'base_plan_123', // Add a suitable base plan ID
  //               },
  //               basePlanId: 'base_plan_123', // Add a suitable base plan ID
  //               offerId: 'offer_123', // Add a suitable offer ID
  //               discount: {
  //                 identifier: 'discount_123',
  //                 price: 3.99,
  //                 currencyCode: 'USD',
  //                 period: '1 month',
  //                 numberOfPeriods: 1,
  //                 type: 'discountType', // Replace with actual discount type
  //               },
  //             },

  //             {
  //               skuId: 'yearly_sku_001',
  //               productId: 'product99999',
  //               store: GLASSFY_STORE.AppStore, // Assuming APP_STORE is one of the GLASSFY_STORE values
  //               introductoryEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
  //               promotionalEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming NOT_ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
  //               extravars: {
  //                 sampleKey: 'sampleValueYearly',
  //               },
  //               product: {
  //                 title: 'Full Access (1 Year)',
  //                 identifier: 'product99999',
  //                 description:
  //                   'Subscribe for a whole year and get the best value!',
  //                 price: 49.99,
  //                 currencyCode: 'USD',
  //                 period: '12 months',
  //                 basePlanId: 'base_plan_123', // Add a suitable base plan ID

  //                 introductoryPrice: {
  //                   identifier: 'intro_yearly_001',
  //                   price: 79.99,
  //                   currencyCode: 'USD',
  //                   period: '12 months',
  //                   numberOfPeriods: 12,
  //                   type: 'introductory',
  //                 },
  //                 discounts: [
  //                   {
  //                     identifier: 'discount_yearly_001',
  //                     price: 84.99,
  //                     currencyCode: 'USD',
  //                     period: '12 months',
  //                     numberOfPeriods: 12,
  //                     type: 'promotional',
  //                   },
  //                 ],
  //               },
  //               basePlanId: 'base_plan_123', // Add a suitable base plan ID
  //               offerId: 'offer_123', // Add a suitable offer ID
  //               discount: {
  //                 identifier: 'discount_123',
  //                 price: 3.99,
  //                 currencyCode: 'USD',
  //                 period: '1 month',
  //                 numberOfPeriods: 1,
  //                 type: 'discountType', // Replace with actual discount type
  //               },
  //             },
  //           ],
  //         },
  //         {
  //           offeringId: 'Snapdown Basic',
  //           skus: [
  //             {
  //               skuId: '1month_monthly_sku_001',
  //               productId: 'product12345',
  //               store: GLASSFY_STORE.AppStore, // Assuming APP_STORE is one of the GLASSFY_STORE values
  //               introductoryEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
  //               promotionalEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming NOT_ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
  //               extravars: {
  //                 sampleKey: 'sampleValue',
  //               },
  //               product: {
  //                 title: 'Basic Access (1 Month)',
  //                 identifier: 'product12345',
  //                 description: 'Subscribe on a monthly basis.',
  //                 price: 2.99,
  //                 currencyCode: 'USD',
  //                 period: '1 month',
  //                 basePlanId: 'base_plan_123', // Add a suitable base plan ID

  //                 introductoryPrice: {
  //                   identifier: 'intro_monthly_001',
  //                   price: 4.99,
  //                   currencyCode: 'USD',
  //                   period: '1 month',
  //                   numberOfPeriods: 1,
  //                   type: 'introductory',
  //                 },
  //                 discounts: [],
  //               },
  //               basePlanId: 'base_plan_123', // Add a suitable base plan ID
  //               offerId: 'offer_123', // Add a suitable offer ID
  //               discount: {
  //                 identifier: 'discount_123',
  //                 price: 3.99,
  //                 currencyCode: 'USD',
  //                 period: '1 month',
  //                 numberOfPeriods: 1,
  //                 type: 'discountType', // Replace with actual discount type
  //               },
  //             },
  //           ],
  //         },
  //       ];
  //       this.offerings$.next(sampleOffering);
  //     }
  //   }

  //   // log whether the offerings are even available on this platform
  //   const user = this.user.getValue();
  //   user.available = isAvailable;

  //   // hack for local testing

  //   // TODO fixme
  //   // user.basic = false;
  //   user.pro = true;
  //   user.available = true;

  //   this.user.next(user);
  // }

  // async purchase(sku: GlassfySku) {
  //   this.spinnerShow();

  //   // const user = this.user.getValue();
  //   // let's reset to base state
  //   // user.pro = false;
  //   // user.basic = true;
  //   // user.sku = undefined;
  //   // this.user.next(user);

  //   try {
  //     let transaction;
  //     let lastSku = this.user.getValue().sku;
  //     if (lastSku) {
  //       let skuToUpgrade = this.user.getValue().sku;
  //       console.log('glassfy, user has sku, upgrading...', skuToUpgrade?.skuId);
  //       transaction = await Glassfy.purchaseSku({ sku, skuToUpgrade });
  //     } else {
  //       console.log('glassfy, user no sku...');
  //       transaction = await Glassfy.purchaseSku({ sku });
  //     }
  //     console.log('glassfy, transaction', JSON.stringify(transaction));
  //     this.errors.push('glassfy, transaction', JSON.stringify(transaction));

  //     // if (transaction.receiptValidated) {
  //     this.handleSuccessfulTransactionResult(transaction, sku);
  //     // } else {
  //     // this.errors.push('not receiptValidated?')
  //     // }
  //   } catch (e) {
  //     console.log('glassfy, purchase error', e);
  //     const alert = await this.alertController.create({
  //       header: 'Purchase failed',
  //       message: e as string,
  //       buttons: ['OK'],
  //     });

  //     await alert.present();
  //   }
  //   this.spinnerHide();
  // }

  // handleExistingPermissions(permissions: GlassfyPermission[]) {
  //   console.log(
  //     'glassfy: Permissions: user has perms',
  //     JSON.stringify(permissions),
  //   );
  //   const user = this.user.getValue();

  //   // let's reset to base state
  //   user.pro = false;
  //   user.basic = false;
  //   user.sku = undefined;

  //   for (const perm of permissions) {
  //     if (perm.isValid) {
  //       if (perm.permissionId === 'pro_features') {
  //         user.pro = true;
  //         console.log('accountable skus', perm.accountableSkus);
  //         if (perm.accountableSkus.length > 0) {
  //           user.sku = perm.accountableSkus[0] as unknown as GlassfySku;
  //         }
  //       }
  //       if (perm.permissionId === 'basic_access') {
  //         console.log('accountable skus', perm.accountableSkus);
  //         if (perm.accountableSkus.length > 0) {
  //           user.sku = perm.accountableSkus[0] as unknown as GlassfySku;
  //         }

  //         user.basic = true;
  //       }
  //     }
  //   }
  //   console.log('user', user);
  //   this.user.next(user);
  // }

  // Helper functions
  getOfferings() {
    return this.offerings$.asObservable();
  }

  getSampleOfferings(): PurchasesOfferings {
    return JSON.parse(`{
      "all": {
          "default": {
              "identifier": "default",
              "serverDescription": "standard offering",
              "metadata": {},
              "availablePackages": [
                  {
                      "identifier": "pro_monthly",
                      "packageType": "CUSTOM",
                      "product": {
                          "identifier": "android_snapdown_pro_1month_9.99:android-snapdown-pro-1month-999",
                          "description": "",
                          "title": "PRO Monthly (Snapdown Wrestling)",
                          "price": 4.99,
                          "priceString": "$4.99",
                          "currencyCode": "USD",
                          "introPrice": null,
                          "discounts": null,
                          "pricePerWeek": 1148383,
                          "pricePerMonth": 4990000,
                          "pricePerYear": 59880000,
                          "pricePerWeekString": "$1.15",
                          "pricePerMonthString": "$4.99",
                          "pricePerYearString": "$59.88",
                          "productCategory": "SUBSCRIPTION",
                          "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                          "subscriptionPeriod": "P1M",
                          "defaultOption": {
                              "id": "android-snapdown-pro-1month-999",
                              "storeProductId": "android_snapdown_pro_1month_9.99:android-snapdown-pro-1month-999",
                              "productId": "android_snapdown_pro_1month_9.99",
                              "pricingPhases": [
                                  {
                                      "billingPeriod": {
                                          "unit": "MONTH",
                                          "value": 1,
                                          "iso8601": "P1M"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$4.99",
                                          "amountMicros": 4990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  }
                              ],
                              "tags": [],
                              "isBasePlan": true,
                              "billingPeriod": {
                                  "unit": "MONTH",
                                  "value": 1,
                                  "iso8601": "P1M"
                              },
                              "isPrepaid": false,
                              "fullPricePhase": {
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$4.99",
                                      "amountMicros": 4990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              },
                              "freePhase": null,
                              "introPhase": null,
                              "presentedOfferingIdentifier": "default",
                              "presentedOfferingContext": {
                                  "offeringIdentifier": "default",
                                  "placementIdentifier": null,
                                  "targetingContext": null
                              },
                              "installmentsInfo": null
                          },
                          "subscriptionOptions": [
                              {
                                  "id": "android-snapdown-pro-1month-999",
                                  "storeProductId": "android_snapdown_pro_1month_9.99:android-snapdown-pro-1month-999",
                                  "productId": "android_snapdown_pro_1month_9.99",
                                  "pricingPhases": [
                                      {
                                          "billingPeriod": {
                                              "unit": "MONTH",
                                              "value": 1,
                                              "iso8601": "P1M"
                                          },
                                          "recurrenceMode": 1,
                                          "billingCycleCount": 0,
                                          "price": {
                                              "formatted": "$4.99",
                                              "amountMicros": 4990000,
                                              "currencyCode": "USD"
                                          },
                                          "offerPaymentMode": null
                                      }
                                  ],
                                  "tags": [],
                                  "isBasePlan": true,
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "isPrepaid": false,
                                  "fullPricePhase": {
                                      "billingPeriod": {
                                          "unit": "MONTH",
                                          "value": 1,
                                          "iso8601": "P1M"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$4.99",
                                          "amountMicros": 4990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  },
                                  "freePhase": null,
                                  "introPhase": null,
                                  "presentedOfferingIdentifier": "default",
                                  "presentedOfferingContext": {
                                      "offeringIdentifier": "default",
                                      "placementIdentifier": null,
                                      "targetingContext": null
                                  },
                                  "installmentsInfo": null
                              }
                          ],
                          "presentedOfferingIdentifier": "default",
                          "presentedOfferingContext": {
                              "offeringIdentifier": "default",
                              "placementIdentifier": null,
                              "targetingContext": null
                          }
                      },
                      "offeringIdentifier": "default",
                      "presentedOfferingContext": {
                          "offeringIdentifier": "default",
                          "placementIdentifier": null,
                          "targetingContext": null
                      }
                  },
                  {
                      "identifier": "pro_annual",
                      "packageType": "CUSTOM",
                      "product": {
                          "identifier": "android_snapdown_pro_1year_49.99:android-snapdown-pro-1year-4999",
                          "description": "",
                          "title": "PRO Yearly (Snapdown Wrestling)",
                          "price": 49.99,
                          "priceString": "$49.99",
                          "currencyCode": "USD",
                          "introPrice": null,
                          "discounts": null,
                          "pricePerWeek": 958712,
                          "pricePerMonth": 4165833,
                          "pricePerYear": 49990000,
                          "pricePerWeekString": "$0.96",
                          "pricePerMonthString": "$4.17",
                          "pricePerYearString": "$49.99",
                          "productCategory": "SUBSCRIPTION",
                          "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                          "subscriptionPeriod": "P1Y",
                          "defaultOption": {
                              "id": "android-snapdown-pro-1year-4999",
                              "storeProductId": "android_snapdown_pro_1year_49.99:android-snapdown-pro-1year-4999",
                              "productId": "android_snapdown_pro_1year_49.99",
                              "pricingPhases": [
                                  {
                                      "billingPeriod": {
                                          "unit": "YEAR",
                                          "value": 1,
                                          "iso8601": "P1Y"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$49.99",
                                          "amountMicros": 49990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  }
                              ],
                              "tags": [],
                              "isBasePlan": true,
                              "billingPeriod": {
                                  "unit": "YEAR",
                                  "value": 1,
                                  "iso8601": "P1Y"
                              },
                              "isPrepaid": false,
                              "fullPricePhase": {
                                  "billingPeriod": {
                                      "unit": "YEAR",
                                      "value": 1,
                                      "iso8601": "P1Y"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$49.99",
                                      "amountMicros": 49990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              },
                              "freePhase": null,
                              "introPhase": null,
                              "presentedOfferingIdentifier": "default",
                              "presentedOfferingContext": {
                                  "offeringIdentifier": "default",
                                  "placementIdentifier": null,
                                  "targetingContext": null
                              },
                              "installmentsInfo": null
                          },
                          "subscriptionOptions": [
                              {
                                  "id": "android-snapdown-pro-1year-4999",
                                  "storeProductId": "android_snapdown_pro_1year_49.99:android-snapdown-pro-1year-4999",
                                  "productId": "android_snapdown_pro_1year_49.99",
                                  "pricingPhases": [
                                      {
                                          "billingPeriod": {
                                              "unit": "YEAR",
                                              "value": 1,
                                              "iso8601": "P1Y"
                                          },
                                          "recurrenceMode": 1,
                                          "billingCycleCount": 0,
                                          "price": {
                                              "formatted": "$49.99",
                                              "amountMicros": 49990000,
                                              "currencyCode": "USD"
                                          },
                                          "offerPaymentMode": null
                                      }
                                  ],
                                  "tags": [],
                                  "isBasePlan": true,
                                  "billingPeriod": {
                                      "unit": "YEAR",
                                      "value": 1,
                                      "iso8601": "P1Y"
                                  },
                                  "isPrepaid": false,
                                  "fullPricePhase": {
                                      "billingPeriod": {
                                          "unit": "YEAR",
                                          "value": 1,
                                          "iso8601": "P1Y"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$49.99",
                                          "amountMicros": 49990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  },
                                  "freePhase": null,
                                  "introPhase": null,
                                  "presentedOfferingIdentifier": "default",
                                  "presentedOfferingContext": {
                                      "offeringIdentifier": "default",
                                      "placementIdentifier": null,
                                      "targetingContext": null
                                  },
                                  "installmentsInfo": null
                              }
                          ],
                          "presentedOfferingIdentifier": "default",
                          "presentedOfferingContext": {
                              "offeringIdentifier": "default",
                              "placementIdentifier": null,
                              "targetingContext": null
                          }
                      },
                      "offeringIdentifier": "default",
                      "presentedOfferingContext": {
                          "offeringIdentifier": "default",
                          "placementIdentifier": null,
                          "targetingContext": null
                      }
                  },
                  {
                      "identifier": "basic_monthly",
                      "packageType": "CUSTOM",
                      "product": {
                          "identifier": "android_snapdown_basic_1month_2.99:android-snapdown-basic-1month",
                          "description": "",
                          "title": "BASIC Monthly (Snapdown Wrestling)",
                          "price": 2.99,
                          "priceString": "$2.99",
                          "currencyCode": "USD",
                          "introPrice": null,
                          "discounts": null,
                          "pricePerWeek": 688109,
                          "pricePerMonth": 2990000,
                          "pricePerYear": 35880000,
                          "pricePerWeekString": "$0.69",
                          "pricePerMonthString": "$2.99",
                          "pricePerYearString": "$35.88",
                          "productCategory": "SUBSCRIPTION",
                          "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                          "subscriptionPeriod": "P1M",
                          "defaultOption": {
                              "id": "android-snapdown-basic-1month",
                              "storeProductId": "android_snapdown_basic_1month_2.99:android-snapdown-basic-1month",
                              "productId": "android_snapdown_basic_1month_2.99",
                              "pricingPhases": [
                                  {
                                      "billingPeriod": {
                                          "unit": "MONTH",
                                          "value": 1,
                                          "iso8601": "P1M"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$2.99",
                                          "amountMicros": 2990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  }
                              ],
                              "tags": [],
                              "isBasePlan": true,
                              "billingPeriod": {
                                  "unit": "MONTH",
                                  "value": 1,
                                  "iso8601": "P1M"
                              },
                              "isPrepaid": false,
                              "fullPricePhase": {
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$2.99",
                                      "amountMicros": 2990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              },
                              "freePhase": null,
                              "introPhase": null,
                              "presentedOfferingIdentifier": "default",
                              "presentedOfferingContext": {
                                  "offeringIdentifier": "default",
                                  "placementIdentifier": null,
                                  "targetingContext": null
                              },
                              "installmentsInfo": null
                          },
                          "subscriptionOptions": [
                              {
                                  "id": "android-snapdown-basic-1month",
                                  "storeProductId": "android_snapdown_basic_1month_2.99:android-snapdown-basic-1month",
                                  "productId": "android_snapdown_basic_1month_2.99",
                                  "pricingPhases": [
                                      {
                                          "billingPeriod": {
                                              "unit": "MONTH",
                                              "value": 1,
                                              "iso8601": "P1M"
                                          },
                                          "recurrenceMode": 1,
                                          "billingCycleCount": 0,
                                          "price": {
                                              "formatted": "$2.99",
                                              "amountMicros": 2990000,
                                              "currencyCode": "USD"
                                          },
                                          "offerPaymentMode": null
                                      }
                                  ],
                                  "tags": [],
                                  "isBasePlan": true,
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "isPrepaid": false,
                                  "fullPricePhase": {
                                      "billingPeriod": {
                                          "unit": "MONTH",
                                          "value": 1,
                                          "iso8601": "P1M"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$2.99",
                                          "amountMicros": 2990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  },
                                  "freePhase": null,
                                  "introPhase": null,
                                  "presentedOfferingIdentifier": "default",
                                  "presentedOfferingContext": {
                                      "offeringIdentifier": "default",
                                      "placementIdentifier": null,
                                      "targetingContext": null
                                  },
                                  "installmentsInfo": null
                              }
                          ],
                          "presentedOfferingIdentifier": "default",
                          "presentedOfferingContext": {
                              "offeringIdentifier": "default",
                              "placementIdentifier": null,
                              "targetingContext": null
                          }
                      },
                      "offeringIdentifier": "default",
                      "presentedOfferingContext": {
                          "offeringIdentifier": "default",
                          "placementIdentifier": null,
                          "targetingContext": null
                      }
                  }
              ],
              "lifetime": null,
              "annual": null,
              "sixMonth": null,
              "threeMonth": null,
              "twoMonth": null,
              "monthly": null,
              "weekly": null
          }
      },
      "current": {
          "identifier": "default",
          "serverDescription": "standard offering",
          "metadata": {},
          "availablePackages": [
              {
                  "identifier": "pro_monthly",
                  "packageType": "CUSTOM",
                  "product": {
                      "identifier": "android_snapdown_pro_1month_9.99:android-snapdown-pro-1month-999",
                      "description": "",
                      "title": "PRO Monthly (Snapdown Wrestling)",
                      "price": 4.99,
                      "priceString": "$4.99",
                      "currencyCode": "USD",
                      "introPrice": null,
                      "discounts": null,
                      "pricePerWeek": 1148383,
                      "pricePerMonth": 4990000,
                      "pricePerYear": 59880000,
                      "pricePerWeekString": "$1.15",
                      "pricePerMonthString": "$4.99",
                      "pricePerYearString": "$59.88",
                      "productCategory": "SUBSCRIPTION",
                      "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                      "subscriptionPeriod": "P1M",
                      "defaultOption": {
                          "id": "android-snapdown-pro-1month-999",
                          "storeProductId": "android_snapdown_pro_1month_9.99:android-snapdown-pro-1month-999",
                          "productId": "android_snapdown_pro_1month_9.99",
                          "pricingPhases": [
                              {
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$4.99",
                                      "amountMicros": 4990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              }
                          ],
                          "tags": [],
                          "isBasePlan": true,
                          "billingPeriod": {
                              "unit": "MONTH",
                              "value": 1,
                              "iso8601": "P1M"
                          },
                          "isPrepaid": false,
                          "fullPricePhase": {
                              "billingPeriod": {
                                  "unit": "MONTH",
                                  "value": 1,
                                  "iso8601": "P1M"
                              },
                              "recurrenceMode": 1,
                              "billingCycleCount": 0,
                              "price": {
                                  "formatted": "$4.99",
                                  "amountMicros": 4990000,
                                  "currencyCode": "USD"
                              },
                              "offerPaymentMode": null
                          },
                          "freePhase": null,
                          "introPhase": null,
                          "presentedOfferingIdentifier": "default",
                          "presentedOfferingContext": {
                              "offeringIdentifier": "default",
                              "placementIdentifier": null,
                              "targetingContext": null
                          },
                          "installmentsInfo": null
                      },
                      "subscriptionOptions": [
                          {
                              "id": "android-snapdown-pro-1month-999",
                              "storeProductId": "android_snapdown_pro_1month_9.99:android-snapdown-pro-1month-999",
                              "productId": "android_snapdown_pro_1month_9.99",
                              "pricingPhases": [
                                  {
                                      "billingPeriod": {
                                          "unit": "MONTH",
                                          "value": 1,
                                          "iso8601": "P1M"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$4.99",
                                          "amountMicros": 4990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  }
                              ],
                              "tags": [],
                              "isBasePlan": true,
                              "billingPeriod": {
                                  "unit": "MONTH",
                                  "value": 1,
                                  "iso8601": "P1M"
                              },
                              "isPrepaid": false,
                              "fullPricePhase": {
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$4.99",
                                      "amountMicros": 4990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              },
                              "freePhase": null,
                              "introPhase": null,
                              "presentedOfferingIdentifier": "default",
                              "presentedOfferingContext": {
                                  "offeringIdentifier": "default",
                                  "placementIdentifier": null,
                                  "targetingContext": null
                              },
                              "installmentsInfo": null
                          }
                      ],
                      "presentedOfferingIdentifier": "default",
                      "presentedOfferingContext": {
                          "offeringIdentifier": "default",
                          "placementIdentifier": null,
                          "targetingContext": null
                      }
                  },
                  "offeringIdentifier": "default",
                  "presentedOfferingContext": {
                      "offeringIdentifier": "default",
                      "placementIdentifier": null,
                      "targetingContext": null
                  }
              },
              {
                  "identifier": "pro_annual",
                  "packageType": "CUSTOM",
                  "product": {
                      "identifier": "android_snapdown_pro_1year_49.99:android-snapdown-pro-1year-4999",
                      "description": "",
                      "title": "PRO Yearly (Snapdown Wrestling)",
                      "price": 49.99,
                      "priceString": "$49.99",
                      "currencyCode": "USD",
                      "introPrice": null,
                      "discounts": null,
                      "pricePerWeek": 958712,
                      "pricePerMonth": 4165833,
                      "pricePerYear": 49990000,
                      "pricePerWeekString": "$0.96",
                      "pricePerMonthString": "$4.17",
                      "pricePerYearString": "$49.99",
                      "productCategory": "SUBSCRIPTION",
                      "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                      "subscriptionPeriod": "P1Y",
                      "defaultOption": {
                          "id": "android-snapdown-pro-1year-4999",
                          "storeProductId": "android_snapdown_pro_1year_49.99:android-snapdown-pro-1year-4999",
                          "productId": "android_snapdown_pro_1year_49.99",
                          "pricingPhases": [
                              {
                                  "billingPeriod": {
                                      "unit": "YEAR",
                                      "value": 1,
                                      "iso8601": "P1Y"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$49.99",
                                      "amountMicros": 49990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              }
                          ],
                          "tags": [],
                          "isBasePlan": true,
                          "billingPeriod": {
                              "unit": "YEAR",
                              "value": 1,
                              "iso8601": "P1Y"
                          },
                          "isPrepaid": false,
                          "fullPricePhase": {
                              "billingPeriod": {
                                  "unit": "YEAR",
                                  "value": 1,
                                  "iso8601": "P1Y"
                              },
                              "recurrenceMode": 1,
                              "billingCycleCount": 0,
                              "price": {
                                  "formatted": "$49.99",
                                  "amountMicros": 49990000,
                                  "currencyCode": "USD"
                              },
                              "offerPaymentMode": null
                          },
                          "freePhase": null,
                          "introPhase": null,
                          "presentedOfferingIdentifier": "default",
                          "presentedOfferingContext": {
                              "offeringIdentifier": "default",
                              "placementIdentifier": null,
                              "targetingContext": null
                          },
                          "installmentsInfo": null
                      },
                      "subscriptionOptions": [
                          {
                              "id": "android-snapdown-pro-1year-4999",
                              "storeProductId": "android_snapdown_pro_1year_49.99:android-snapdown-pro-1year-4999",
                              "productId": "android_snapdown_pro_1year_49.99",
                              "pricingPhases": [
                                  {
                                      "billingPeriod": {
                                          "unit": "YEAR",
                                          "value": 1,
                                          "iso8601": "P1Y"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$49.99",
                                          "amountMicros": 49990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  }
                              ],
                              "tags": [],
                              "isBasePlan": true,
                              "billingPeriod": {
                                  "unit": "YEAR",
                                  "value": 1,
                                  "iso8601": "P1Y"
                              },
                              "isPrepaid": false,
                              "fullPricePhase": {
                                  "billingPeriod": {
                                      "unit": "YEAR",
                                      "value": 1,
                                      "iso8601": "P1Y"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$49.99",
                                      "amountMicros": 49990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              },
                              "freePhase": null,
                              "introPhase": null,
                              "presentedOfferingIdentifier": "default",
                              "presentedOfferingContext": {
                                  "offeringIdentifier": "default",
                                  "placementIdentifier": null,
                                  "targetingContext": null
                              },
                              "installmentsInfo": null
                          }
                      ],
                      "presentedOfferingIdentifier": "default",
                      "presentedOfferingContext": {
                          "offeringIdentifier": "default",
                          "placementIdentifier": null,
                          "targetingContext": null
                      }
                  },
                  "offeringIdentifier": "default",
                  "presentedOfferingContext": {
                      "offeringIdentifier": "default",
                      "placementIdentifier": null,
                      "targetingContext": null
                  }
              },
              {
                  "identifier": "basic_monthly",
                  "packageType": "CUSTOM",
                  "product": {
                      "identifier": "android_snapdown_basic_1month_2.99:android-snapdown-basic-1month",
                      "description": "",
                      "title": "BASIC Monthly (Snapdown Wrestling)",
                      "price": 2.99,
                      "priceString": "$2.99",
                      "currencyCode": "USD",
                      "introPrice": null,
                      "discounts": null,
                      "pricePerWeek": 688109,
                      "pricePerMonth": 2990000,
                      "pricePerYear": 35880000,
                      "pricePerWeekString": "$0.69",
                      "pricePerMonthString": "$2.99",
                      "pricePerYearString": "$35.88",
                      "productCategory": "SUBSCRIPTION",
                      "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                      "subscriptionPeriod": "P1M",
                      "defaultOption": {
                          "id": "android-snapdown-basic-1month",
                          "storeProductId": "android_snapdown_basic_1month_2.99:android-snapdown-basic-1month",
                          "productId": "android_snapdown_basic_1month_2.99",
                          "pricingPhases": [
                              {
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$2.99",
                                      "amountMicros": 2990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              }
                          ],
                          "tags": [],
                          "isBasePlan": true,
                          "billingPeriod": {
                              "unit": "MONTH",
                              "value": 1,
                              "iso8601": "P1M"
                          },
                          "isPrepaid": false,
                          "fullPricePhase": {
                              "billingPeriod": {
                                  "unit": "MONTH",
                                  "value": 1,
                                  "iso8601": "P1M"
                              },
                              "recurrenceMode": 1,
                              "billingCycleCount": 0,
                              "price": {
                                  "formatted": "$2.99",
                                  "amountMicros": 2990000,
                                  "currencyCode": "USD"
                              },
                              "offerPaymentMode": null
                          },
                          "freePhase": null,
                          "introPhase": null,
                          "presentedOfferingIdentifier": "default",
                          "presentedOfferingContext": {
                              "offeringIdentifier": "default",
                              "placementIdentifier": null,
                              "targetingContext": null
                          },
                          "installmentsInfo": null
                      },
                      "subscriptionOptions": [
                          {
                              "id": "android-snapdown-basic-1month",
                              "storeProductId": "android_snapdown_basic_1month_2.99:android-snapdown-basic-1month",
                              "productId": "android_snapdown_basic_1month_2.99",
                              "pricingPhases": [
                                  {
                                      "billingPeriod": {
                                          "unit": "MONTH",
                                          "value": 1,
                                          "iso8601": "P1M"
                                      },
                                      "recurrenceMode": 1,
                                      "billingCycleCount": 0,
                                      "price": {
                                          "formatted": "$2.99",
                                          "amountMicros": 2990000,
                                          "currencyCode": "USD"
                                      },
                                      "offerPaymentMode": null
                                  }
                              ],
                              "tags": [],
                              "isBasePlan": true,
                              "billingPeriod": {
                                  "unit": "MONTH",
                                  "value": 1,
                                  "iso8601": "P1M"
                              },
                              "isPrepaid": false,
                              "fullPricePhase": {
                                  "billingPeriod": {
                                      "unit": "MONTH",
                                      "value": 1,
                                      "iso8601": "P1M"
                                  },
                                  "recurrenceMode": 1,
                                  "billingCycleCount": 0,
                                  "price": {
                                      "formatted": "$2.99",
                                      "amountMicros": 2990000,
                                      "currencyCode": "USD"
                                  },
                                  "offerPaymentMode": null
                              },
                              "freePhase": null,
                              "introPhase": null,
                              "presentedOfferingIdentifier": "default",
                              "presentedOfferingContext": {
                                  "offeringIdentifier": "default",
                                  "placementIdentifier": null,
                                  "targetingContext": null
                              },
                              "installmentsInfo": null
                          }
                      ],
                      "presentedOfferingIdentifier": "default",
                      "presentedOfferingContext": {
                          "offeringIdentifier": "default",
                          "placementIdentifier": null,
                          "targetingContext": null
                      }
                  },
                  "offeringIdentifier": "default",
                  "presentedOfferingContext": {
                      "offeringIdentifier": "default",
                      "placementIdentifier": null,
                      "targetingContext": null
                  }
              }
          ],
          "lifetime": null,
          "annual": null,
          "sixMonth": null,
          "threeMonth": null,
          "twoMonth": null,
          "monthly": null,
          "weekly": null
      }
  }`);
  }
}
