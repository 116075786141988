<div *ngIf="showSplash" class="splash">
  <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div>
</div>

<ion-app [class.dark-theme]="dark">
  <ion-router-outlet></ion-router-outlet>
</ion-app>
<!-- <ngx-spinner></ngx-spinner>
 -->
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.6)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen]="false"
  name="matchups"
>
  <p style="font-size: 20px; color: white">
    Hang tight, we are crunching thousands of matchups!
  </p>
</ngx-spinner>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.6)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen]="false"
>
</ngx-spinner>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.6)"
  size="medium"
  name="blank"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen]="false"
>
